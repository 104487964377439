.leaflet-container{
    min-height: 80.6vh;
    width: 100%;
  }

  .leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}

  .leaflet-routing-container .leaflet-bar .leaflet-control{
  display: none;
}

   .leaflet-top .leaflet-right{
  display: none;
}


.leaflet-top .leaflet-left,
.leaflet-control-zoom .leaflet-bar .leaflet-control{
  display: none;
}

.react-bootstrap-table-pagination {
  width: 100%;
}

.search-label{
  width: 100%;
}

@media (min-width: 1367px) {
  .modal-xl {
      max-width: 1300px;
  }
}

.container-fluid {
  height: 97vh;
  max-height: 97vh;
  overflow: auto;
  overflow-x: hidden;
}

@media print {
  .container-fluid {
      height: max-content;
      max-height: max-content;
      width: 100vh;
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(192, 194, 196);
  border-radius: 500px;
}

::-webkit-scrollbar-track {
  background: transparent;
}